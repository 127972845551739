<template>
  <div class="record">
    <div class="backlink ama18">
      <router-link
              :to="`/tjalie/${$route.params.line}/${$route.params.point}`" 
            >
            <img src="@/assets/images/leftarrow.svg" />Tjalieclopedie
            </router-link>
    </div>
    <div class="topimage">
      <img
        :src="
          `${$config.images.server}/images/${record.recordmedia}?width=${windowWidth}&height=590&fit=cover&background=ffffff&amp;position=left%20top`
        "
      />
    </div>
    <div class="data">
      <div class="keywords ama18">
        <span class="keyword" v-for="keyword in keywords" :key="keyword">
          {{ keyword }}
        </span>
      </div>
      <div class="title ama60">
        {{ record.title }}
      </div>
      <div class="subtitle ama40">
        {{ record.subtitle }}
      </div>
      <div class="description pop24">
        <span v-html="record.description" />
      </div>
    </div> 
  </div>
</template>

<script>
import lifeService from "../services/life_service"

export default {
  data() {
    return {
      record: {},
      windowWidth: (this.windowWidth = window.innerWidth),
      keywords: {},
      prevRoute: null
    }
  },
  recordservice: null,
  created() {
    this.lifeService = new lifeService()
  },
  mounted() {
    //this.recordservice.getById(this.$router.currentRoute.value.params.id).then(data => this.record = data);
    var t = this
    this.lifeService.getPoint(this.$route.params.point).then((data) => {
      t.record = data
      t.keywords = t.record.keywords ? t.record.keywords.split(";") : ""
    })
    //if (!this.record.title) this.$router.push("/404")
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {},
}
</script>

<style scoped>
.record {
  width: 100%;
  background-color: #201022;
  color: white;
}
.topimage {
  height: 590px;
}
.data {
  margin-left: 294px;
  margin-top: 35px;
  margin-right: 294px;
}
.data img {
  max-width: 100%;
}
.title {
  margin-top: 20px;
}
.keyword {
  background-color: #ef5e33;
  height: 50px;
  width: fit-content;
  padding: 11px;
  cursor: pointer;
  margin: 30px 20px 20px 0px;
}
.backlink {
  position: absolute;
  top: 104px;
  left: 69px;
  cursor: pointer;
}
.backlink img {
  margin-right: 10px;
}
</style>
